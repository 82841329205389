





























































import Vue from "vue";
import axios from "axios";
import { RuleFinding } from "@/types/rule_finding";

export default Vue.extend({
  name: "resultsComponent",
  data: () => ({
    results: [],
    documentTypes: ["Alle"],
    importanceLevels: ["Alle", "Info", "Waarschuwing", "Fout"],
    selectedDocumentType: "Alle",
    selectedImportanceLevel: "Alle",
    tableHeaders: [
      {
        text: "Gewicht",
        value: "Rule.importance",
        sortable: false,
      },
      {
        text: "Bestandsnaam",
        value: "Finding.filename",
      },
      {
        text: "Regel-id",
        value: "Finding.rule_id",
      },
      {
        text: "Datum",
        value: "Finding.timestamp",
      },
      {
        text: "Feedback",
        value: "Finding.feedbackMessage",
      },
    ],
  }),
  computed: {
    filteredResults: function (): string[] {
      return this.results.filter(
        (result: RuleFinding) =>
          (result.Rule.object_type == this.selectedDocumentType ||
            this.selectedDocumentType === "Alle") &&
          (result.Rule.importance >=
            this.importanceLevels.indexOf(this.selectedImportanceLevel) ||
            this.selectedImportanceLevel === "Alle")
      );
    },
  },
  methods: {
    getresults() {
      axios
        .get(`${this.$store.state.APIurl}/finding`, {
          headers: { Authorization: `Bearer ${this.$store.state.user.token}` },
        })
        .then((response) => {
          this.$data.results = response.data;
          const presentTypes: string[] = [
            ...new Set(
              this.$data.results.map(
                (result: RuleFinding) => result.Rule.object_type
              )
            ),
          ] as string[];
          this.$data.documentTypes = ["Alle"].concat(presentTypes);
        })
        .catch(() => {
          this.$data.results = [];
        });
    },
  },
  mounted() {
    this.getresults();
  },
});
